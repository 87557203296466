import React from 'react';

import './styles.css';
import {withRouter} from "react-router-dom";
import logoCompactoExt from "../../../img/logo/logo_simples_white.svg";


class OnBoarding extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0
        }
    }

    title = () => {
        return {"login": "Login", "registo": "Registo", "recuperar": "Recuperar Password"}[this.props.page]
    };

    form = () => {
        switch (this.props.page) {
            case "registo":
                return <form>
                    <input type="text" placeholder={"Nome"}/>
                    <input type="text" placeholder={"Email"}/>
                    <input type="password" placeholder={"Password"}/>
                    <input type="password" placeholder={"Repetir Password"}/>
                    <label className={"checkbox"}><input type="checkbox"/><span/> Li e aceito os termos e condições</label>
                    <label className={"checkbox"}><input type="checkbox"/><span/> Aceito receber conteúdos de marketing</label>
                </form>;
            case "login":
                return <form>
                    <input type="text" placeholder={"Email"}/>
                    <input type="password" placeholder={"Password"}/>
                </form>;
            case "recuperar":
                return <form style={{marginTop: "50px"}}>
                    <input type="text" placeholder={"Email"}/>
                </form>
            default:
                return <form>
                </form>
        }
    };

    action = () => {
        switch (this.props.page) {
            case "registo":
                return <a className={"form-action"} onClick={() => {
                    window.App.setLoggedIn();
                }}>{this.title()}</a>;
            case "login":
                return <React.Fragment><a className={"form-action"} onClick={() => {
                    window.App.setLoggedIn();
                }}>{this.title()}</a>
                    <a className={"button-link d-block"} onClick={() => {
                        this.props.history.replace("/recuperar")
                    }}>Esqueceu-se da palavra passe?</a>
                </React.Fragment>;
            case "recuperar":
                return <a className={"form-action"} onClick={() => {
                    this.props.history.replace("/login")
                }}>{this.title()}</a>
        }

    };

    bottom_action = () => {
        switch (this.props.page) {
            case "registo":
                return <React.Fragment>
                    <span className={"auth-label"}>Tem uma conta? <a className={"button-link-in"} onClick={() => {
                        this.props.history.replace("/login")
                    }}>Faça Login</a></span>
                    <a className={"bottom_action"} onClick={() => {
                        window.App.setLoggedIn();
                    }}>Skip</a></React.Fragment>;
            case "login":
                return <React.Fragment>
                    <span className={"auth-label"}>Não tem uma conta? <a className={"button-link-in"} onClick={() => {
                        this.props.history.replace("/registo")
                    }}>Registe-se</a></span>
                    <a className={"bottom_action"} onClick={() => {
                        window.App.setLoggedIn();
                    }}>Skip</a></React.Fragment>;
            case "recuperar":
                return <a className={"bottom_action"} onClick={() => {
                    this.props.history.replace("/login")
                }}>Regressar</a>
        }

    };

    render() {
        return (
            <div className={"OnBoarding"}>

                <div className="logo" style={{backgroundImage: "url(" + logoCompactoExt + ")"}}/>
                <div className="wrapper container">
                    <h4 className={"title"}>{this.title()}</h4>
                    <div className={"form"}>
                        {this.form()}
                        {this.action()}
                    </div>
                </div>

                {this.bottom_action()}
            </div>
        );
    }
}

export default withRouter(OnBoarding);
