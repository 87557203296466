import React from 'react';
import './styles.css';

const pino_natureza = require("../../../img/marcadores_mapa/pino_natureza.svg");
const pino_restauracao = require("../../../img/marcadores_mapa/pino_restauracao.svg");
const pino = require("../../../img/marcadores_mapa/pino.svg");

export default class PontoMapa extends React.Component {
    constructor(props) {
        super(props);
    }

    pino = () => {
        switch (this.props.cat) {
            case "NATUREZA_AVENTURA":
                return <img src={pino_natureza}/>;
            case "SENSACOES_SABORES":
                return <img src={pino_restauracao}/>;
            default:
                return <img src={pino}/>;
        }
    };

    render() {
        return <div tabIndex="-1" className={"PontoMapa"}>
            {this.pino()}
        </div>;
    }
}