import React, {Component} from 'react';
import {withCookies} from "react-cookie";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {CSSTransition, TransitionGroup} from 'react-transition-group';

import MenuPlanear from "../img/menu/Planear.svg";
import MenuHome from "../img/menu/Home.svg";
import MenuDescobrir from "../img/menu/Descobrir.svg";
import MenuPerfil from "../img/menu/Perfil.svg";
import MenuTools from "../img/menu/Tools.svg";

import FadeView from "./components/fade_view";
import BottomTabNavigation from "./components/bottom_tab_navigation"

import Splashscreen from "./pages/splashscreen";
import Apresentacao from "./pages/apresentacao";
import OnBoarding from "./pages/onboarding";
import Inicio from "./pages/inicio";
import Planear from "./pages/planear";
import Detalhes from "./pages/detalhes";

const {homepage, version} = require("../../package.json");

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            loggedIn: false
        };

        window.App = this;

        window.onhashchange = () => {
            let page = window.location.hash.substring(1);
            if (page === 'logout')
                this.logout();
        };

        setTimeout(this.checkStatus, 1500);

        fetch(homepage + "meta.json")
            .then(res => res.json())
            .then((data) => {
                if (data.version && data.version !== version && window.confirm("Nova versão encontrada. Atualizar?")) {
                    if (caches) {
                        // Service worker cache should be cleared with caches.delete()
                        caches.keys().then(function (names) {
                            for (let name of names) caches.delete(name);
                        });
                    }
                    window.location.reload(true);
                }
            })
    }

    checkStatus = () => {
        this.setState({ready: true, loggedIn: this.props.cookies.get("LoggedIn")})
    };

    setLoggedIn = () => {
        this.props.cookies.set("LoggedIn", true);
        this.checkStatus();
    };

    logout = () => {
        this.setState({
            loggedIn: false
        });
        this.props.cookies.remove("LoggedIn");
    };

    render() {
        let app = null;

        if (this.state.loggedIn)
            app = <Router history={this.history} basename={homepage}>
                <Route render={({location}) => {
                    return <TransitionGroup>
                        <CSSTransition
                            key={location.key}
                            timeout={300}
                            classNames='fade'
                        >
                            <Switch location={location}>
                                <Route path="/inicio" exact={true}>
                                    <div className="RouteWrapper">
                                        <Inicio/>
                                    </div>
                                </Route>
                                <Route path="/planear" exact={true}>
                                    <div className="RouteWrapper">
                                        <Planear/>
                                    </div>
                                </Route>
                                <Route path="/descobrir" exact={true}>
                                    <div className="RouteWrapper">
                                        descobrir
                                    </div>
                                </Route>
                                <Route path="/perfil" exact={true}>
                                    <div className="RouteWrapper">
                                        perfil
                                    </div>
                                </Route>
                                <Route path="/ferramentas" exact={true}>
                                    <div className="RouteWrapper">
                                        ferramentas
                                    </div>
                                </Route>
                                <Route path="/detalhes" exact={true}>
                                    <div className="RouteWrapper">
                                        <Detalhes/>
                                    </div>
                                </Route>
                                <Route path="/experiencia/:id" exact={true}>
                                    <div className="RouteWrapper">
                                        experiencia
                                    </div>
                                </Route>
                                <Redirect from="/" to="/inicio"/>
                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                }}/>
                <BottomTabNavigation buttons={[
                    {page: "/inicio", icon: MenuHome, title: "Home"},
                    {page: "/planear", icon: MenuPlanear, title: "Planear"},
                    {icon: MenuDescobrir, title: "Descobrir"},
                    {icon: MenuPerfil, title: "Perfil"},
                    {icon: MenuTools, title: "Tools"}
                ]}/>
            </Router>;
        else
            app = <Router history={this.history} basename={homepage}>
                <Route render={({location}) => {
                    return <TransitionGroup>
                        <CSSTransition
                            key={location.key}
                            timeout={300}
                            classNames='fade'
                        >
                            <Switch location={location}>
                                <Route path="/apresentacao" exact={true}>
                                    <div className="RouteWrapper fullscreen">
                                        <Apresentacao/>
                                    </div>
                                </Route>
                                <Route path="/registo" exact={true}>
                                    <div className="RouteWrapper fullscreen">
                                        <OnBoarding page={"registo"}/>
                                    </div>
                                </Route>
                                <Route path="/login" exact={true}>
                                    <div className="RouteWrapper fullscreen">
                                        <OnBoarding page={"login"}/>
                                    </div>
                                </Route>
                                <Route path="/recuperar" exact={true}>
                                    <div className="RouteWrapper fullscreen">
                                        <OnBoarding page={"recuperar"}/>
                                    </div>
                                </Route>
                                <Redirect from="/" to="/apresentacao"/>
                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                }}/>
            </Router>;

        return <FadeView active={this.state.ready} className={"flex"}>
            <Splashscreen/>
            {app}
        </FadeView>
    }
}

export default withCookies(App);
