import React, {Component} from 'react';
import './styles.css';
import {Link, matchPath, useLocation} from "react-router-dom";


function Button({page, icon, title}) {
    let location = useLocation();
    let match = matchPath(location.pathname, {
        path: page,
        exact: true
    });
    return <Link to={page} className={"BottomTabNavigationButton" + (match ? " active" : "")}>
        <i style={{webkitMaskImage: "url(" + icon + ")", maskImage: "url(" + icon + ")"}}/>
        <span>{title}</span></Link>
}

class BottomTabNavigation extends Component {
    render() {
        return (<div className="BottomTabNavigation">
            {this.props.buttons.map((button) => <Button {...button}/>)}
        </div>);
    }
}

export default BottomTabNavigation;