import React, {useState} from 'react';
import './styles.css';
import ItemsCarousel from "react-items-carousel";
import Bullets from "../bullets";

import estrela from "./icons/star_open.svg";
import estrelaAtivada from "./icons/star_fill.svg";
import coracao from "./icons/heart.svg";
import coracaoAtivado from "./icons/heart_fill.svg";

function Card(props) {
    const [favorito, setFavorito] = useState(props.favorito);
    const [estrelas, setEstrelas] = useState(props.estrelas);

    switch (props.appearance || "default") {
        case "default":
            return <div className={"Card default " + (props.current ? "current" : "not_current")}>
                <div className={"image"} style={{backgroundImage: "url(" + props.imagem + ")"}}/>
                <h5 className={"color-primary"}>{props.titulo}</h5>
                <p className={"color-secondary"}>{props.categoria}</p>
            </div>;
        case "special":
            return <div className={"Card special " + (props.current ? "current" : "not_current")}>
                <div className={"image"} style={{backgroundImage: "url(" + props.imagem + ")"}}>
                    <Estrelas estrelas={estrelas} onChange={estrelas => setEstrelas(estrelas)}/>
                    <div className={"botao_favorito"} onClick={(e) => {
                        e.stopPropagation();
                        setFavorito(!favorito)
                    }}><img src={favorito ? coracaoAtivado : coracao}/></div>
                </div>
                <h5 className={"color-primary"}>{props.titulo}</h5>
                <p className={"color-secondary"}>{props.categoria}</p>
            </div>;
        case "image":
            return <div className={"Card place " + (props.current ? "current" : "not_current")}>
                <div className={"image"} style={{backgroundImage: "url(" + props.imagem + ")"}}/>
            </div>;
    }
    return null;
}

function Estrelas(props) {
    let estrelas = Math.min(props.estrelas - 1, 4);
    return <div className={"Estrelas"}>
        {Array(5).fill(null).map((_, idx) => <img onClick={(e) => {
            e.stopPropagation();
            props.onChange && props.onChange(idx + 1)
        }} src={idx <= estrelas ? estrelaAtivada : estrela}/>)}
    </div>;
}

function Caroussel(props) {
    const [activeIndex, setActiveindex] = useState(0);

    return <div className={"Caroussel " + (props.appearance || "default")}>
        <div className={props.appearance !== 'image' ? "container" : ""}>
            <ItemsCarousel
                infiniteLoop={props.appearance === 'image'}
                requestToChangeActive={(idx) => {
                    if (idx > -1 && idx < props.cards.length) {
                        setActiveindex(idx);
                    }
                }}
                activeItemIndex={activeIndex}
                numberOfCards={1}
                gutter={props.appearance !== 'image' ? 20 : 0}
                classes={{itemsWrapper: "itemsWrapper", itemWrapper: "itemWrapper", wrapper: "wrapper"}}
            >
                {props.cards.map((card, idx) => <div className={"cursor-pointer"} onClick={(e) => {
                    if (activeIndex !== idx) {
                        setActiveindex(idx);
                    } else {
                        props.onClick && props.onClick(idx);
                    }
                }}>
                    <Card appearance={props.appearance} current={activeIndex === idx} {...card}/>
                </div>)}
            </ItemsCarousel>
            {props.withBullets && <Bullets color={"#22b9ad"} count={props.cards.length} step={activeIndex} onSelect={idx => setActiveindex(idx)}/>}
        </div>
    </div>;
}

export default Caroussel;