import React from 'react';

import logoCompactoExt from "../../../img/logo/logo_compacto_ext_white.svg";
import logoCompactoInt from "../../../img/logo/logo_compacto_int_white.svg";
import logoBemHaja from "../../../img/logo/logo_bem_haja_white.svg";

import './styles.css';

class Splashscreen extends React.Component {

    render() {
        return (
            <div className={"Splashscreen"}>
                <div className={"center"}>
                    <div>
                        <div className="logo">
                            <div style={{backgroundImage: "url(" + logoCompactoExt + ")"}}/>
                            <div style={{backgroundImage: "url(" + logoCompactoInt + ")"}}/>
                        </div>
                        <img className={"bem_haja"} src={logoBemHaja}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Splashscreen;