import React from 'react';

import './styles.css';
import {withRouter} from "react-router-dom";
import seta_back from "./seta_back.svg";
import seta from "./seta.svg";
import pin from "./pin.svg";

import GoogleMapReact from "google-map-react";
import Caroussel from "../../components/caroussel";
import Collapsible from "react-collapsible";
import PontoMapa from "../../components/ponto_mapa";

const map_style = require('./map_style.json');

class Detalhes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <div className={"Detalhes"}>
                <div className={"CabecalhoInicio"}>
                    <div className={"container"}>
                        <img onClick={this.props.history.goBack} style={{transform: "rotate(180deg)", marginTop: "20px"}} className={"icon"} src={seta_back}/>
                        <p className={"title-top"}>Experiência</p>
                        <p className={"sub-title-top"}>Serra da Estrela Trial 3 dias</p>
                    </div>
                </div>
                <div className={"container width-100"}>
                    <div className={"video_header"}>
                        <iframe src="https://player.vimeo.com/video/394311137?title=0&byline=0&portrait=0" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen/>
                    </div>
                </div>
                <div className={"container"}>
                    <p style={{color: "#788FBF", marginTop: "25px"}} className={"sub-title-top"}>Lorem Ipsum Set Amet</p>
                    <p style={{fontWeight: "600", color: "#07B9AD"}} className={"title-top"}>DURAÇÃO: 3 dias</p>
                    <p style={{fontWeight: "600", color: "#07B9AD"}} className={"title-top"}>CUSTO: 200€ por pessoa</p>
                    <div style={{display: "flex", alignItems: "center", marginTop: "25px"}}>
                        <img style={{marginRight: "10px"}} className={"icon"} src={pin}/>
                        <p style={{marginTop: "0", fontSize: "13px", fontWeight: "600", color: "#07B9AD"}} className={"title-top"}>Largo do Côro, 6430-081 Marialva GPS 40°54’53.1”N 7°13’55.8”W </p>
                    </div>
                    <p style={{marginBottom: "35px", marginTop: "25px", fontWeight: "500", fontSize: "15px", lineHeight: "1.6"}} className={"sub-title-top"}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                </div>
                <div style={{height: "270px"}} className={"mapa"}>
                    <GoogleMapReact
                        options={{
                            fullscreenControl: false,
                            zoomControl: false,
                            disableDefaultUI: true,
                            styles: map_style,
                            gestureHandling: 'cooperative'
                        }}
                        bootstrapURLKeys={{key: "AIzaSyDsurKWNXyTnkFhR9lTzx0GrfuEY0bhslc"}}
                        defaultZoom={10}
                        defaultCenter={{lat: 40.137433, lng: -7.501610}}
                        styles={{}}>
                        <PontoMapa lat={40.137433} lng={-7.501610}/>
                    </GoogleMapReact>
                </div>
                <Caroussel
                    appearance={"image"}
                    withBullets
                    cards={[{imagem: require("./header_imgs/header_2.jpg")}, {imagem: require("./header_imgs/header_1.png")}]}/>
                <div className={"seccao container detalhes_local"}>
                    <label className={"color-secondary"}>Onde?</label>
                    <h5 className={"color-primary"}>FUNDÃO</h5>
                    <p className={"sub-title-top"}>
                        O Fundão é uma cidade portuguesa no distrito de Castelo Branco, na província da Beira Baixa, região do Centro (Região das Beiras) e sub-região das Beiras e Serra da Estrela, com cerca de 8 750 habitantes.
                        <br/>
                        É sede de um município com 700,20 km² de área e 29 213 habitantes (2011), subdividido em 23 freguesias. O município é limitado a norte pelos municípios da Covilhã, Belmonte e Sabugal, a leste por Penamacor e Idanha-a-Nova, a sul por Castelo Branco, a sudoeste por Oleiros e a oeste por Pampilhosa da Serra.
                    </p>
                </div>
                <div className={"seccao purple"}>
                    <div className={"container"}>
                        <p style={{marginTop: "10px"}} className={"sub-title-bold"}>Detalhes</p>
                        <p style={{marginTop: "10px", fontSize: "23px", marginBottom: 10}} className={"sub-title-bold"}>Planeamento Diário</p>
                        <Collapsible
                            classParentString={"collapsible"}
                            trigger={<div className={"header"}>
                                <h3>DIA 1</h3>
                                <img className={"chevron"} src={seta}/>
                            </div>}>
                            <p>
                                Exercitation in fugiat est ut ad ea cupidatat ut in
                                cupidatat occaecat ut occaecat consequat est minim minim
                                esse tempor laborum consequat esse adipisicing eu
                                reprehenderit enim.
                            </p>
                        </Collapsible>
                        <Collapsible
                            classParentString={"collapsible"}
                            trigger={<div className={"header"}>
                                <h3>DIA 2</h3>
                                <img className={"chevron"} src={seta}/>
                            </div>}>
                            <p>
                                In ad velit in ex nostrud dolore cupidatat consectetur
                                ea in ut nostrud velit in irure cillum tempor laboris
                                sed adipisicing eu esse duis nulla non.
                            </p>
                        </Collapsible>
                        <Collapsible
                            classParentString={"collapsible"}
                            trigger={<div className={"header"}>
                                <h3>DIA 3</h3>
                                <img className={"chevron"} src={seta}/>
                            </div>}>
                            <p>
                                Exercitation in fugiat est ut ad ea cupidatat ut in
                                cupidatat occaecat ut occaecat consequat est minim minim
                                esse tempor laborum consequat esse adipisicing eu
                                reprehenderit enim.
                            </p>
                        </Collapsible>
                    </div>
                </div>
                <div className={"seccao preco"} style={{backgroundColor: "#07b9ad"}}>
                    <div>
                        <div className={"background"}/>
                        <div className={"content"}>
                            <p style={{fontWeight: "600", fontSize: "25px"}}>CUSTO</p>
                            <p style={{fontSize: "28px"}}>200€</p>
                            <p style={{fontSize: "18px"}}>por pessoa</p>
                            <a>MARCAR JÁ</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Detalhes);