import React, {useState} from 'react';

import './styles.css';
import {withRouter} from "react-router-dom";

import lupa from "./lupa.svg";
import seta from "./seta.svg";
import Caroussel from "../../components/caroussel";

import Select, {components as ReactSelectComponents} from "react-select";

const selectProps = {
    styles: {
        singleValue: styles => ({...styles, color: "white", fontWeight: 300, fontSize: 14}),
        container: styles => ({...styles, marginTop: 14}),
        control: styles => ({margin: "0 -6px", padding: "5px 0", display: "flex", color: "white"}),
        indicatorsContainer: styles => ({width: "35px"}),
        placeholder: styles => ({color: "rgba(255,255,255,0.7)", fontWeight: 300, fontSize: 14}),
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            return {
                ...styles,
                backgroundColor: "white",
                color: isSelected ? '#07B9AD' : '#A5A5A5',
                fontSize: 12,
                cursor: isDisabled ? 'not-allowed' : 'default',
            };
        },
        dropdownIndicator: (provided, state) => ({
            ...provided,
            transition: "all 0.3s",
            transform: state.selectProps.menuIsOpen && 'scaleY(-1)'
        })
    },
    theme: theme => ({
        ...theme,
        borderRadius: 0,
    }),
    components: {
        Control: props => {
            const {children, cx, getStyles, className, isDisabled, isFocused, innerRef, innerProps, menuIsOpen} = props;
            return <div>
                <div
                    ref={innerRef}
                    style={getStyles('control', props)}
                    className={cx(
                        {
                            control: true,
                            'control--is-disabled': isDisabled,
                            'control--is-focused': isFocused,
                            'control--menu-is-open': menuIsOpen,
                        },
                        className
                    )}
                    {...innerProps}
                >
                    {children}
                </div>
                <div style={{borderBottom: "1px solid white"}}></div>
            </div>
        },
        IndicatorSeparator: null,
        DropdownIndicator: (props => {
            return (
                <ReactSelectComponents.DropdownIndicator {...props}>
                    <img className={"chevron"} src={seta} style={{height: 10}}/>
                </ReactSelectComponents.DropdownIndicator>
            );
        })
    },
    isSearchable: false,
};

function Separadores(props) {
    const itens = [
        "Criar a viagem",
        "Comunidade",
        "Ajuda",
        "Dicas"
    ];
    const [ativo, setAtivo] = useState(1);

    return <div className={"Separadores"}>
        <div>
            {itens.map((item, idx) => <li onClick={() => setAtivo(idx)} className={ativo === idx ? "active" : ""}>{item}{idx < itens.length - 1 && <span>&#124;</span>}</li>)}
        </div>
    </div>
}

class Planear extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        console.log(this.state);
        return (
            <div className={"Planear"}>
                <div className={"Cabecalho"}>
                    <div className={"container"}>
                        <Separadores/>
                    </div>
                </div>
                <div className={"seccao green"}>
                    <div className={"section-title container"}>
                        <p className={"sub-title"}>Criar a viagem</p>
                    </div>
                    <div className={"container pesquisar_localizacao"}>
                        <p style={{marginTop: "35px"}} className={"sub-title-bold"}>Sei o que quero</p>
                        <div className={"input_pesquisa"}>
                            <input type="text" placeholder={"Pesquisar por localização"}/>
                            <span className={"pesquisar"} style={{backgroundImage: "url(" + lupa + ")"}}/>
                        </div>
                    </div>
                </div>
                <div className={"seccao purple"}>
                    <div className={"container"}>
                        <p style={{marginTop: "10px"}} className={"sub-title-bold"}>Quero ir</p>
                        <Select
                            value={this.state.categoria}
                            onChange={(e) => {
                                this.setState({
                                    categoria: e
                                });
                            }}
                            placeholder={"Categorias"}
                            options={[
                                {value: 0, label: 'Natureza & Aventura'},
                                {value: 1, label: 'Cultura & História'},
                                {value: 2, label: 'Sensações & Sabores'},
                                {value: 3, label: 'Saúde & Bem Estar'},
                            ]}
                            {...selectProps}
                        />
                        <Select
                            value={this.state.localizacao}
                            onChange={(e) => {
                                this.setState({
                                    localizacao: e
                                });
                            }}
                            placeholder={"Localização"}
                            options={[
                                {value: 0, label: 'Manteigas'},
                                {value: 1, label: 'Sabugueiro'},
                                {value: 2, label: 'Valhelhas'},
                                {value: 3, label: 'Famalicão'},
                                {value: 4, label: 'São Julião'},
                                {value: 5, label: 'Loriga'},
                            ]}
                            {...selectProps}
                        />
                        <Select
                            value={this.state.dias}
                            onChange={(e) => {
                                this.setState({
                                    dias: e
                                });
                            }}
                            placeholder={"Número de dias"}
                            options={[
                                {value: 0, label: '1 dia'},
                                {value: 1, label: '2 dias'},
                                {value: 2, label: '3 dias'},
                                {value: 3, label: '4 dias'},
                            ]}
                            {...selectProps}
                        />
                        <Select
                            value={this.state.pessoas}
                            onChange={(e) => {
                                this.setState({
                                    pessoas: e
                                });
                            }}
                            placeholder={"Número de pessoas"}
                            options={[
                                {value: 0, label: '1 pessoa'},
                                {value: 1, label: '2 pessoas'},
                                {value: 2, label: '3 pessoas'},
                                {value: 3, label: '4 pessoas'},
                            ]}
                            {...selectProps}
                        />
                        <div className={"search_block"}>
                            <div>
                                <p>Pesquisar</p>
                                <span className={"pesquisar"} style={{backgroundImage: "url(" + lupa + ")"}}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"seccao"}>
                    <div className={"section-title container"}>
                        <h4>Sugestões</h4>
                    </div>
                    <Caroussel
                        appearance={"special"}
                        withBullets
                        cards={[
                            {
                                titulo: "Esqui na Torre",
                                categoria: "Desporto",
                                estrelas: 3,
                                favorito: false,
                                imagem: require("./curadoria/1.jpg")
                            },
                            {
                                titulo: "Passeio pela Serra",
                                categoria: "Desafio",
                                estrelas: 5,
                                favorito: true,
                                imagem: require("./curadoria/2.jpg")
                            },
                            {
                                titulo: "Campismo Rural",
                                categoria: "Desporto",
                                estrelas: 5,
                                favorito: false,
                                imagem: require("./curadoria/3.jpg")
                            }
                        ]}
                        onClick={() => {
                            this.props.history.push("/detalhes");
                        }}/>
                </div>
                <div className={"categorias"}>
                    <h4>Outras indicações</h4>
                    <div className={"container"}>
                        <div className={"categoria"}><span style={{backgroundImage: "url(" + require("../../../img/categorias/natureza_aventura_white.svg") + ")"}}/></div>
                        <div className={"categoria"}><span style={{backgroundImage: "url(" + require("../../../img/categorias/cultura_historia_white.svg") + ")"}}/></div>
                        <div className={"categoria"}><span style={{backgroundImage: "url(" + require("../../../img/categorias/sensasoes_sabores_white.svg") + ")"}}/></div>
                        <div className={"categoria"}><span style={{backgroundImage: "url(" + require("../../../img/categorias/saude_bem_estar_white.svg") + ")"}}/></div>
                    </div>
                </div>
            </div>
        )
            ;
    }
}

export default withRouter(Planear);