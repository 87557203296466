import React from 'react';

import './styles.css';
import ItemsCarousel from 'react-items-carousel';
import {withRouter} from "react-router-dom";
import Bullets from "../../components/bullets";

function Slide(props) {
    return <div className={"Slide"}>
        <div className={"image"}>
            <div style={{backgroundImage: "url(" + props.image + ")"}}/>
        </div>
        <h3>{props.titulo}</h3>
        <p>{props.subtitulo}</p>
    </div>
}

class Apresentacao extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0,
            slides: [
                {image: require("./slides/1.jpg")},
                {image: require("./slides/2.jpg")},
                {image: require("./slides/3.jpg")},
                {image: require("./slides/4.jpg")}
            ]
        }
    }

    render() {
        return (
            <div className={"Apresentacao"}>
                <ItemsCarousel
                    requestToChangeActive={(idx) => {
                        if (idx > -1 && idx < this.state.slides.length) {
                            this.setState({activeIndex: idx});
                        }
                    }}
                    activeItemIndex={this.state.activeIndex}
                    numberOfCards={1}
                    classes={{itemsWrapper: "itemsWrapper", itemWrapper: "itemWrapper", wrapper: "wrapper"}}
                >
                    {this.state.slides.map(slide => <Slide {...slide}/>)}
                </ItemsCarousel>
                <div className={"bottom"}>
                    <Bullets count={this.state.slides.length} step={this.state.activeIndex} onSelect={idx => this.setState({activeIndex: idx})}/>
                    <a className={"bottom_action" + (this.state.activeIndex < this.state.slides.length - 1 ? " disabled" : "")} style={{marginTop: 10}} onClick={() => {
                        this.props.history.replace("/login")
                    }}>Continuar</a>
                </div>
            </div>
        );
    }
}

export default withRouter(Apresentacao);