import React, {Children, PureComponent} from 'react';

import "./styles.css";

export default class FadeView extends PureComponent {
    static defaultProps = {
        animationDuration: 500,
        active: false,
        removeHiddenSubviews: true,
    };

    constructor(props) {
        super(props);

        this.renderChild = this.renderChild.bind(this);
        this.mounted = false;

        let {active} = this.props;

        this.state = {
            animating: false,
        };
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentWillReceiveProps({active, animationDuration}) {
        let {progress} = this.state;

        if (active ^ this.props.active) {
            this.setState({animating: true});

            setTimeout(() => {
                if (this.mounted)
                    this.setState({animating: false});
            }, animationDuration);
        }
    }

    renderChild(child, index) {
        let {active, removeHiddenSubviews} = this.props;
        let {animating} = this.state;

        let hidden = active ^ !!index;

        if (removeHiddenSubviews) {
            if (!animating && hidden) {
                return null;
            }
        }

        let pointerEvents = hidden ?
            'none' :
            'initial';

        return (
            <div style={{pointerEvents}} className={"wrapper " + (animating ? (hidden ? "fadeOut" : "fadeIn") : '')}>
                {child}
            </div>
        );
    }

    render() {
        let {children, ...props} = this.props;

        return (
            <div {...props} className={(this.props.className || '') + " FadeView"}>
                {Children.map(children, this.renderChild)}
            </div>
        );
    }
}
