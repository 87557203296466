import React, {useState} from 'react';

import './styles.css';
import {withRouter} from "react-router-dom";
import GoogleMapReact from 'google-map-react';

import lupa from "./lupa.svg";
import seta from "./seta.svg";
import Caroussel from "../../components/caroussel";
import PontoMapa from "../../components/ponto_mapa";

const map_style = require('./map_style.json');
const pontos_de_interesse = require('./pontos_de_interesse.json');

function Filtros(props) {
    const itens = [
        {cod: null, label: "Tudo"},
        {cod: "NATUREZA_AVENTURA", label: "Natureza & Aventura"},
        {cod: "SENSACOES_SABORES", label: "Sensações & Sabores"},
        {cod: "SAUDE_BEM_ESTAR", label: "Saúde & Bem Estar"},
        {cod: "CULTURA_HISTORIA", label: "Cultura & História"},
    ];
    const [ativo, setAtivo] = useState(0);

    return <div className={"Filtros"}>
        <div>
            {itens.map((item, idx) => <li onClick={() => {
                setAtivo(idx);
                props.applyFilter && props.applyFilter(item.cod);
            }} className={ativo === idx ? "active" : ""}>{item.label}</li>)}
        </div>
    </div>
}

class Inicio extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pontos_mapa: pontos_de_interesse
        }
    }

    render() {
        return (
            <div className={"Inicio"}>
                <div className={"CabecalhoInicio"}>
                    <div className={"topo"}>
                        <span className={"avatar"} onClick={window.App.logout}
                              style={{backgroundImage: "url(" + require("./avatar.png") + ")"}}/>
                        <h4>BEM-VINDA, Maria</h4>
                        <span className={"pesquisar"} style={{backgroundImage: "url(" + lupa + ")"}}/>
                    </div>
                </div>
                <Filtros applyFilter={(filtro) => {
                    this.setState({pontos_mapa: pontos_de_interesse.filter(ponto => !filtro || ponto.cat === filtro)});
                }}/>
                <div className={"mapa"}>
                    <GoogleMapReact
                        options={{
                            fullscreenControl: false,
                            zoomControl: false,
                            disableDefaultUI: true,
                            styles: map_style,
                            gestureHandling: 'cooperative'
                        }}
                        bootstrapURLKeys={{key: "AIzaSyDsurKWNXyTnkFhR9lTzx0GrfuEY0bhslc"}}
                        defaultZoom={10}
                        defaultCenter={{lat: 40.356499, lng: -7.626980}}
                        styles={{}}>
                        {this.state.pontos_mapa.map(ponto => {
                            let coordenadas = ponto.coord.split(",");
                            let lat = parseFloat(coordenadas[0].trim());
                            let lng = parseFloat(coordenadas[1].trim());
                            return <PontoMapa {...ponto} lat={lat} lng={lng}/>
                        })}

                    </GoogleMapReact>
                </div>
                <div className={"seccao"}>
                    <div className={"section-title container"}>
                        <h4>Não podes perder</h4>
                        <p className={"cursor-pointer"}>Ver todos<img className={"icon"} src={seta}/></p>
                    </div>
                    <Caroussel cards={[
                        {
                            titulo: "Covão da Ametade",
                            categoria: "Paisagem",
                            imagem: require("./locais/1.jpg")
                        },
                        {
                            titulo: "Poço do Inferno",
                            categoria: "Paisagem",
                            imagem: require("./locais/2.jpg")
                        },
                        {
                            titulo: "Alfatima",
                            categoria: "Gastronomia",
                            imagem: require("./locais/3.jpg")
                        }
                    ]}
                               onClick={() => {
                                   this.props.history.push("/detalhes");
                               }}/>
                </div>
                <hr/>
                <div className={"seccao"}>
                    <div className={"section-title container"}>
                        <h4>Beiras e Serra da Estrela Sugerem</h4>
                    </div>
                    <Caroussel
                        appearance={"special"}
                        withBullets
                        cards={[
                            {
                                titulo: "Esqui na Torre",
                                categoria: "Desporto",
                                estrelas: 3,
                                favorito: false,
                                imagem: require("./curadoria/1.jpg")
                            },
                            {
                                titulo: "Passeio pela Serra",
                                categoria: "Desafio",
                                estrelas: 5,
                                favorito: true,
                                imagem: require("./curadoria/2.jpg")
                            },
                            {
                                titulo: "Campismo Rural",
                                categoria: "Desporto",
                                estrelas: 5,
                                favorito: false,
                                imagem: require("./curadoria/3.jpg")
                            }
                        ]}
                        onClick={() => {
                            this.props.history.push("/detalhes");
                        }}/>
                </div>
                <div className={"categorias"}>
                    <h4>Experiências por Categoria</h4>
                    <div className={"container"}>
                        <div className={"categoria"}><span style={{backgroundImage: "url(" + require("../../../img/categorias/natureza_aventura_white.svg") + ")"}}/></div>
                        <div className={"categoria"}><span style={{backgroundImage: "url(" + require("../../../img/categorias/cultura_historia_white.svg") + ")"}}/></div>
                        <div className={"categoria"}><span style={{backgroundImage: "url(" + require("../../../img/categorias/sensasoes_sabores_white.svg") + ")"}}/></div>
                        <div className={"categoria"}><span style={{backgroundImage: "url(" + require("../../../img/categorias/saude_bem_estar_white.svg") + ")"}}/></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Inicio);