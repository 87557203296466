import React from 'react';
import ReactDOM from 'react-dom';

import App from './app/App';
import registerServiceWorker from './registerServiceWorker';

import './css/general.css';
import './css/text.css';
import './css/animations.css';
import './css/buttons.css';

if (!Object.values) {
    Object.values = function (obj) {
        return Object.keys(obj).map(function (key) {
            return obj[key];
        });
    }
}

ReactDOM.render(<App/>, document.getElementById('root'));
registerServiceWorker();
